/* src/components/Gallery.css */
.gallery {
  padding: 4rem 2rem;
  color: #fff;
  text-align: center;
  position: relative;
}

.gallery::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1; /* Low opacity to avoid overpowering content */
  z-index: 0;
}

.gallery h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #d4af37; /* Gold accent */
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  position: relative;
  z-index: 1;
}

.nft-card {
  background: #222;
  border: 1px solid #333;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column; /* Column direction for image and text */
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  padding: 1rem; /* Add some padding inside the card */
  min-height: 350px; /* Ensure the card has enough height for centering */
}

.nft-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
}

.nft-card img {
  border-radius: 5px;
  max-width: 100%;
  max-height: 250px; /* Increase the height of the image */
  object-fit: contain; /* Ensure the image fits inside without cropping */
  display: block;
  margin: auto; /* Center the image horizontally */
}

.nft-info {
  padding: 1rem;
  text-align: center; /* Center text inside the card */
}

.nft-info h3 {
  color: #d4af37;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.buy-button {
  display: inline-block;
  margin-top: 1rem; /* Spacing above the button */
  padding: 0.75rem 1.5rem;
  background-color: #d4af37; /* Gold background */
  color: #000; /* Black text color */
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.buy-button:hover {
  background-color: #b38e29; /* Darker gold on hover */
  color: #fff; /* White text on hover */
}
