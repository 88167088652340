/* src/components/PricingAvailability.css */
.pricing-availability {
  color: #fff;
  padding: 4rem 2rem;
  background: #111; /* Optional: Add a background color to distinguish the section */
}

.pricing-availability .content {
  display: flex; /* Use flexbox for side-by-side layout */
  align-items: center; /* Center content vertically */
  justify-content: space-between; /* Add space between text and image */
  max-width: 1200px;
  margin: 0 auto; /* Center the content horizontally */
  gap: 2rem; /* Add space between text and image */
}

.pricing-availability .text {
  flex: 1; /* Take up more space for text */
  max-width: 600px; /* Optional: Limit the width of the text */
}

.pricing-availability h2 {
  font-size: 2rem;
  color: #d4af37;
  margin-bottom: 1rem;
}

.pricing-availability p {
  font-size: 1.1rem;
  line-height: 1.6; /* Increase line height for better readability */
}

.pricing-availability .image-container {
  flex: 1; /* Allow the image container to take up available space */
  display: flex;
  justify-content: center; /* Center the image horizontally */
}

.pricing-availability img {
  max-width: 100%; /* Ensure image scales correctly */
  height: auto; /* Maintain aspect ratio */
  max-height: 300px; /* Limit the height to match the text block */
  object-fit: contain; /* Ensure the image fits within the container */
  border-radius: 5px;
}
