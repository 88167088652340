/* General Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.85); /* Dark background with slight transparency */
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  border-bottom: 2px solid #d4af37; /* Gold accent border for dramatic effect */
  box-sizing: border-box; /* Include padding in width calculation */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.logo-container {
  display: flex;
  align-items: center; /* Vertically center the logo */
}

.logo-image {
  max-height: 50px; /* Set a max height for the logo */
  width: auto; /* Maintain aspect ratio */
  margin-right: 1rem; /* Space between logo and links */
  border-radius: 15px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem; /* Adjust gap as needed */
  margin: 0;
  padding: 0; /* Remove default padding */
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: 600;
  position: relative;
  padding: 0.5rem; /* Add padding for better click area */
}

.nav-links a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background: #d4af37; /* Gold underline on hover */
  transition: width 0.3s;
}

.nav-links a:hover {
  color: #d4af37;
}

.nav-links a:hover::after {
  width: 100%;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .navbar {
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  .nav-links {
    position: fixed;
    top: 60px;
    right: 0;
    width: 250px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95); /* Darker background */
    flex-direction: column; /* Stack links vertically */
    align-items: flex-start;
    padding: 2rem 1rem;
    gap: 1rem; /* Space between links */
    display: none; /* Initially hidden */
    z-index: 999;
  }

  .nav-links a {
    padding: 1rem 0; /* Increased padding for touch-friendly links */
    width: 100%;
  }

  .nav-links.active {
    display: flex; /* Show menu when active */
  }

  .hamburger {
    display: flex; /* Show hamburger on mobile */
  }

  /* Hamburger animation for open state */
  .hamburger.open div:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.open div:nth-child(2) {
    opacity: 0; /* Hide middle line */
  }

  .hamburger.open div:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
