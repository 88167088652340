.discord-section {
  color: #fff;
  padding: 4rem 2rem;
  text-align: left;
}

.discord-section .content {
  max-width: 800px;
  margin: 0 auto;
}

.discord-section h2 {
  font-size: 2rem;
  color: #d4af37;
  margin-bottom: 1rem;
}

.discord-section p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.discord-section a {
  color: #f1f1f1; /* Discord color */
}

.discord-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #7289da; /* Discord button color */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.discord-button:hover {
  background-color: #5a6c99; /* Slightly darker Discord button color */
}
