/* src/components/PurchaseDetails.css */
.purchase-details {

    color: #fff;
    padding: 4rem 2rem;
  }
  
  .purchase-details .content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .purchase-details h2 {
    font-size: 2rem;
    color: #d4af37;
    margin-bottom: 1rem;
  }
  
  .purchase-details p {
    font-size: 1.1rem;
  }
  