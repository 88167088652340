/* src/components/Footer.css */
.footer {
    background-color: #000;
    color: #fff;
    padding: 1rem 2rem;
    text-align: center;
  }
  
  .footer p {
    margin: 0;
  }
  