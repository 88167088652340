/* src/components/OwnershipTransfer.css */
.ownership-transfer {

    color: #fff;
    padding: 4rem 2rem;
  }
  
  .ownership-transfer .content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .ownership-transfer h2 {
    font-size: 2rem;
    color: #d4af37;
    margin-bottom: 1rem;
  }
  
  .ownership-transfer p {
    font-size: 1.1rem;
  }
  