/* src/components/Hero.css */
.hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #fff;
    text-align: center;
    z-index: 2; /* Keep hero content above the background */
    background: transparent; /* No color blocking the background */
  }
  
  /* Hero Background Image */
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./prison-bars.png') no-repeat center center / cover; /* Add your hero image */
    z-index: 1; /* Ensure this is above the texture but below the hero content */
    opacity: 0.9; /* Optional: adjust to make the image blend slightly if needed */
  }
  
  .hero-content {
    position: relative;
    z-index: 2; /* Ensure content is above the image */
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: #d4af37; /* Gold accent for a striking look */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #e0e0e0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  }
  
  .cta-button {
    background-color: #d4af37;
    color: #000;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 5px;
  }
  
  .cta-button:hover {
    background-color: #b3872a;
    transform: scale(1.05);
  }
  