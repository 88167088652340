/* src/App.css */

/* Base styles */
body {
  font-family: 'Poppins', sans-serif;
  background: #000; /* Fallback color */
  color: #fff;
  line-height: 1.6;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Main App Container */
.App {
  padding-top: 80px; /* Offset for the fixed navbar */
  background: url('./components/grunge-texture.png') repeat; /* Ensure the path is correct */
  background-size: auto; /* Avoid scaling the texture */
  position: relative;
  min-height: 100vh;
  z-index: 0; /* Ensure it's behind all other elements */
}

/* Lighten the overlay to make the texture visible */
.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Lighten the overlay to 50% opacity */
  pointer-events: none;
  z-index: 1;
}

/* Adjust gradient overlay to ensure texture visibility */
.App::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.6)
  );
  z-index: 1;
  pointer-events: none;
}

.App > * {
  position: relative;
  z-index: 2; /* Ensure content is above overlays */
}

/* Responsive Layout */
@media (max-width: 768px) {
  .App {
    padding-top: 60px; /* Adjust for smaller screens */
  }
}
