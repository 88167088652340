/* src/components/TransactionProcess.css */
.transaction-process {

    color: #fff;
    padding: 4rem 2rem;
  }
  
  .transaction-process .content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .transaction-process h2 {
    font-size: 2rem;
    color: #d4af37;
    margin-bottom: 1rem;
  }
  
  .transaction-process p {
    font-size: 1.1rem;
  }
  
  .transaction-process a {
    color: #d4af37;
    text-decoration: underline;
  }
  